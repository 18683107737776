
.details-title {
    cursor: pointer;
    padding: 10px 18px;

    @media (min-width: 1200px) {
        font-weight: bold;
    }
}

.details-table {
    padding: 0;
    list-style: none;
}
.detail {
    padding: 10px 18px;

    &:nth-child(odd) {
        background-color: var(--global-muted-background);
    }

}

.refresh-info {
    font-size: 12px;
    font-weight: bold;
    color: var(--global-success-background);

    @media (min-width: 1200px) {
        font-size: 14px;
    }
}


